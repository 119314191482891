import { createBrowserHistory } from 'history';
import React, { lazy, Suspense } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";

import "./App.css";

const BplanBuilder = lazy(() => import("./components/partials/10-bplanBuilder/Internal/BplanBuilder"));
const GenerateBplan = lazy(() => import("./components/partials/10-bplanBuilder/GenerateBplan"));
const BplanBuilderCustomer = lazy(() => import("./components/partials/10-bplanBuilder/Customer/BplanBuilderCustomer"));

const V2BplanBuilder = lazy(() => import("./v2/components/partials/10-bplanBuilder/Internal/BplanBuilder"));
const V2GenerateBplan = lazy(() => import("./v2/components/partials/10-bplanBuilder/GenerateBplan"));
const V2BplanBuilderCustomer = lazy(() => import("./v2/components/partials/10-bplanBuilder/Customer/BplanBuilderCustomer"));

const history = createBrowserHistory();

function App() {
	return (
		<Suspense fallback={<div className="spinner-border text-primary" role="status" style={{ left: "50%", position: "absolute", top: "50%" }}><span className="sr-only">Loading...</span></div>}>
			<Router history={history}>
				<Switch>
					{/* <Route path="/mother-bplan/new" component={BplanBuilder} exact /> */}
					<Route path="/mother-bplan/:bplanId" component={BplanBuilder} exact />

					{/* <Route path="/new-bplan" component={BplanBuilderCustomer} exact /> */}
					<Route path="/edit-bplan/:bplanId" component={BplanBuilderCustomer} exact />

					<Route path="/bplan/:id" component={GenerateBplan} exact />
					<Route path="/generate/pdf/:id" component={GenerateBplan} exact />

					<Route path="/v2/mother-bplan/new" component={V2BplanBuilder} exact /> {/** Unchanged */}
					<Route path="/v2/mother-bplan/:bplanId" component={V2BplanBuilder} exact />  {/** Unchanged */}

					<Route path="/v2/new-bplan" component={V2BplanBuilderCustomer} exact />
					<Route path="/v2/edit-bplan/:bplanId" component={V2BplanBuilderCustomer} exact />

					<Route path="/v2/bplan/:id" component={GenerateBplan} exact />
					{/* <Route path="/v2/generate/pdf/:id" component={V2GenerateBplan} exact /> */}

					{/** @TODO - Add v2 new bplan default route */}
					<Redirect path="*" to="/v2/new-bplan" />
				</Switch>
			</Router>
		</Suspense>
	);
}

export default App;